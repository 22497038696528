export const CodeOfConductTags = [
  "#AI",
  "#Actors",
  "#Agile",
  "#Architecture",
  "#BigData",
  "#CD",
  "#CI",
  "#Cloud",
  "#Concurrency",
  "#DDD",
  "#DevOps",
  "#Docker",
  "#Frontend",
  "#FunctionalProgramming",
  "#GoLang",
  "#Integration",
  "#IoT",
  "#JVMLangs",
  "#Java",
  "#JavaScript",
  "#Kubernetes",
  "#MachineLearning",
  "#Mesos",
  "#Microservices",
  "#Mobile",
  "#Python",
  "#Rust",
  "#SQA",
  "#Scalability",
  "#TDD",
  "#Testing",
  "#TypeScript",
  "#WebAssembly",
  "#noSQL",
  "Kotlin",
];
