export interface SessionRating {
  id: string;
  Title: string;
  talk_id?: number;
  Overall: number;
  Fun: number;
  Originality: number;
  Creativity: number;
  Communication: number;
  Speaker_knowledge: number;
  Pace_and_timing: number;
  Recommend_to_others: number;
  Insight: number;
  votes: number;
}

export type MeasurableSessionRating = Omit<SessionRating, "id" | "Title">;

export const sessionData: SessionRating[] = [
  {
    id: "9a5a8f11-df49-4f6e-9407-f8818c4625aa",
    Title:
      "Cassandra Made Easy: Interact with your Data using Stargate HTTP APIs",
    talk_id: 442359,
    Overall: 4,
    Fun: 4,
    Originality: 4,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 4,
    Pace_and_timing: 4,
    Recommend_to_others: 7,
    Insight: 0,
    votes: 1,
  },
  {
    id: "f695d26a-561d-4653-b6a8-964905d2f9bf",
    Title: "The Cloud Native Compiler: JIT-as-a-Service",
    talk_id: 435427,
    Overall: 4.5,
    Fun: 4,
    Originality: 4,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 8,
    Insight: 0,
    votes: 2,
  },
  {
    id: "47047079-f816-4cb5-8583-e6da81a45ba4",
    Title: "Understanding the Go Compiler",
    talk_id: 448552,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "71191f4e-3f22-4a86-a34f-312f7d4fbdc5",
    Title: "Vertex AI: Pipelines for your MLOps workflows",
    talk_id: 443851,
    Overall: 4,
    Fun: 3,
    Originality: 4,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 5,
    Pace_and_timing: 4,
    Recommend_to_others: 8,
    Insight: 0,
    votes: 1,
  },
  {
    id: "c984fa2f-d6c6-48cf-ae97-441f992459c1",
    Title: "JBang and the prisoner of the release",
    talk_id: 463234,
    Overall: 4,
    Fun: 4,
    Originality: 4,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 9,
    Insight: 0,
    votes: 1,
  },
  {
    id: "59dcf069-7621-4c42-8bb6-70f09dd74013",
    Title: "From IL Weaving to Source Generators the Realm story",
    talk_id: 432859,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "d455ba82-6ad3-4737-b749-6c437a4d7a27",
    Title: "Tech Sherpas: 5 Keys for Effective Leadership",
    talk_id: 451526,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "b4099ed2-89e8-4350-91c3-7bf0d6514bea",
    Title:
      "Embracing tRPC for Next-Level Typesafe API Development in Full-Stack TypeScript",
    talk_id: 445491,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "44585808-2a80-4bec-ad4c-2be4ad25e49d",
    Title: "Corporate BigData: From Onpremise to Cloud on Applus+IDIADA",
    talk_id: 461443,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "8b580cce-d14c-4eb6-bcf6-a38fe39fc7c3",
    Title: "Enriching postal addresses with Elastic stack",
    talk_id: 438037,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "3244928e-b8a0-45ab-92d6-8c831221969d",
    Title:
      "Beneficios y dificultades que (quizá) no pensaste de usar Event-Sourcing.",
    talk_id: 448072,
    Overall: 4.69,
    Fun: 4.46,
    Originality: 4.38,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 4.62,
    Recommend_to_others: 9.23,
    Insight: 0,
    votes: 13,
  },
  {
    id: "ddc2448f-3461-4df2-95e2-2aa1fa708db4",
    Title:
      "OpenTelemetry for GitOps: Tracing Deployments from Git Commit to Production on K8s",
    talk_id: 452943,
    Overall: 5,
    Fun: 5,
    Originality: 5,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 9,
    Insight: 0,
    votes: 1,
  },
  {
    id: "4ef7759a-b7ca-43ae-a5a2-2127906c8b90",
    Title: "Securing Secrets in the GitOps era",
    talk_id: 441185,
    Overall: 4.17,
    Fun: 4.17,
    Originality: 4,
    Creativity: 0,
    Communication: 4.5,
    Speaker_knowledge: 4.67,
    Pace_and_timing: 4.5,
    Recommend_to_others: 8.33,
    Insight: 0,
    votes: 6,
  },
  {
    id: "207f9bbd-1323-460d-9e54-028e3d474dd3",
    Title: "Como implementar un cambio desde abajo con Digital Leaders",
    talk_id: 428595,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "1ded980c-7b24-4bb9-be03-1f9884462439",
    Title: "Jakarta EE! The future of enterprise application behind the myths.",
    talk_id: 429286,
    Overall: 3.5,
    Fun: 3.5,
    Originality: 3.5,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 4.5,
    Pace_and_timing: 4.5,
    Recommend_to_others: 8,
    Insight: 0,
    votes: 2,
  },
  {
    id: "d4895da1-7eb9-4377-8073-417f185dc3fe",
    Title:
      "Cómo hemos convertido una DB open source en un SaaS multi-tenant usando K8s",
    talk_id: 464380,
    Overall: 5,
    Fun: 5,
    Originality: 5,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 10,
    Insight: 0,
    votes: 1,
  },
  {
    id: "b4ab901c-1b08-45f7-950e-d2b5d6b299e9",
    Title: "Learning from Failures: Ways to Improve Chances of Success",
    talk_id: 453660,
    Overall: 4.06,
    Fun: 3.69,
    Originality: 3.97,
    Creativity: 0,
    Communication: 4.31,
    Speaker_knowledge: 4.56,
    Pace_and_timing: 4.19,
    Recommend_to_others: 8.16,
    Insight: 0,
    votes: 32,
  },
  {
    id: "df2006b8-12ae-48ce-8af7-a99031160bb5",
    Title: "CRDT and other new ideas for client-server communication",
    talk_id: 424809,
    Overall: 4,
    Fun: 3.67,
    Originality: 3.67,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 4.33,
    Pace_and_timing: 4.33,
    Recommend_to_others: 8.33,
    Insight: 0,
    votes: 3,
  },
  {
    id: "955629e0-ec6d-453f-977f-a57b086b1823",
    Title: "Stop building APIs",
    talk_id: 445951,
    Overall: 4,
    Fun: 4,
    Originality: 4.63,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 4.75,
    Pace_and_timing: 4.25,
    Recommend_to_others: 7.5,
    Insight: 0,
    votes: 8,
  },
  {
    id: "28b6a108-e732-4452-aa55-c9fd7934ecf1",
    Title: "Conseguimos migrar de Openshift a AWS EKS con near-zero downtime",
    talk_id: 451982,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "40a0354e-0ffe-4c9a-9e4f-fc1fd3117365",
    Title: "How and why ($) to improve web performance in 2023",
    talk_id: 457013,
    Overall: 4,
    Fun: 4,
    Originality: 4,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 9,
    Insight: 0,
    votes: 1,
  },
  {
    id: "c2cff2e6-00c1-4318-98ac-d969b9a3a313",
    Title: "Creating Psychologically Safe Engineering Teams",
    talk_id: 424358,
    Overall: 4.4,
    Fun: 3.8,
    Originality: 4.4,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 4.2,
    Pace_and_timing: 4.4,
    Recommend_to_others: 8,
    Insight: 0,
    votes: 5,
  },
  {
    id: "5261e1a1-8dea-4f0b-838f-6d0785180e64",
    Title: "Welcome to the Jungle - A safari through the JVM landscape",
    talk_id: 422743,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "b64b87d6-dc92-4f4e-83ee-87dbc878011e",
    Title:
      "About Giants Liars and Slow Pokes...A (Unit-) Test-Antipattern-Fairytale",
    talk_id: 426026,
    Overall: 3.67,
    Fun: 3.6,
    Originality: 3.8,
    Creativity: 0,
    Communication: 3.47,
    Speaker_knowledge: 3.93,
    Pace_and_timing: 3.67,
    Recommend_to_others: 6.8,
    Insight: 0,
    votes: 15,
  },
  {
    id: "a0544cb9-cc6e-48c9-91c8-b93802206a62",
    Title: "Reactive Java",
    talk_id: 464137,
    Overall: 5,
    Fun: 5,
    Originality: 5,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 10,
    Insight: 0,
    votes: 1,
  },
  {
    id: "83addc86-d52d-4fb6-aaf8-92cb3b013851",
    Title: "Reducing the K8s pain for developers in a multi-cloud world",
    talk_id: 452012,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "c978cf5c-0380-4505-b350-9e15588e7220",
    Title: "Monta tu propio ChatGPT!",
    talk_id: 463992,
    Overall: 4.64,
    Fun: 4.73,
    Originality: 4.55,
    Creativity: 0,
    Communication: 4.55,
    Speaker_knowledge: 4.73,
    Pace_and_timing: 4.27,
    Recommend_to_others: 8.73,
    Insight: 0,
    votes: 11,
  },
  {
    id: "21151dd5-aeda-4bac-b041-23b83f0a9dc4",
    Title: "Alejándonos de ReactJs | Clean architecture en el frontend",
    talk_id: 443322,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "a3b31d82-0ca2-4ccd-bcac-9e9cbafd804e",
    Title: "Squeezing a go function",
    talk_id: 448553,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "ab93a0ec-d956-4e15-9b4c-1784774011f2",
    Title: "Machine learning in the browser using TensorFlow.js",
    talk_id: 429846,
    Overall: 5,
    Fun: 5,
    Originality: 5,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 10,
    Insight: 0,
    votes: 1,
  },
  {
    id: "051ff8d0-76db-4eba-b0e6-002751466cf6",
    Title:
      "From Chaos to Order: How Angular Monorepos Can Simplify Your Codebase",
    talk_id: 455058,
    Overall: 5,
    Fun: 5,
    Originality: 4.5,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 4.5,
    Pace_and_timing: 5,
    Recommend_to_others: 9.5,
    Insight: 0,
    votes: 2,
  },
  {
    id: "d9c3325c-091b-4327-9ef9-8d1f0fce3d0b",
    Title:
      "Measure Twice Cut Once: Dive Into Network Foundations For Developers!",
    talk_id: 452718,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "2c90ac16-dd13-4d7e-84e9-3505aeddbe1b",
    Title: "How to avoid common pitfalls with modern microservices testing",
    talk_id: 423678,
    Overall: 4,
    Fun: 4.8,
    Originality: 4.6,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 4.6,
    Pace_and_timing: 4.6,
    Recommend_to_others: 8.2,
    Insight: 0,
    votes: 5,
  },
  {
    id: "5e83ed05-2ae2-4107-a4bf-7e73c54d78c0",
    Title: "Dreams complaints and agility: taking Calimero for a walk",
    talk_id: 464505,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "8b34681c-ed79-4aac-997b-af730846943e",
    Title: "Deserialization exploits in Java: why should I care?",
    talk_id: 424242,
    Overall: 4.91,
    Fun: 4.55,
    Originality: 4.73,
    Creativity: 0,
    Communication: 4.91,
    Speaker_knowledge: 5,
    Pace_and_timing: 4.82,
    Recommend_to_others: 9.64,
    Insight: 0,
    votes: 11,
  },
  {
    id: "997ad8ea-8eb6-429e-8f15-5ea90cf7f93e",
    Title: "AI is MagIA",
    talk_id: 435422,
    Overall: 3.4,
    Fun: 4.4,
    Originality: 5,
    Creativity: 0,
    Communication: 4.2,
    Speaker_knowledge: 3.2,
    Pace_and_timing: 3.8,
    Recommend_to_others: 6.4,
    Insight: 0,
    votes: 5,
  },
  {
    id: "fa576e1f-9fb9-44ce-81f2-11759a10e6c3",
    Title: "Measuring the Cost of a GraphQL Query",
    talk_id: 462757,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "525adb91-df0a-4fc9-a34f-29b7835cee89",
    Title: "Major migrations made easy",
    talk_id: 429442,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "6f7b0de3-ee32-4285-a334-ee20ecc489c0",
    Title: "A Healthy diet for your Java application",
    talk_id: 463323,
    Overall: 4.67,
    Fun: 4.33,
    Originality: 4.5,
    Creativity: 0,
    Communication: 4.67,
    Speaker_knowledge: 4.67,
    Pace_and_timing: 4.5,
    Recommend_to_others: 9.33,
    Insight: 0,
    votes: 6,
  },
  {
    id: "f8a73698-cce2-4a58-8379-ce7d1dd8f37b",
    Title:
      "Beyond Tables and Bottlenecks: How We Joined High Volumes of Data in Real Time",
    talk_id: 497591,
    Overall: 4.33,
    Fun: 3.67,
    Originality: 4.67,
    Creativity: 0,
    Communication: 3.67,
    Speaker_knowledge: 4.67,
    Pace_and_timing: 4.33,
    Recommend_to_others: 8.33,
    Insight: 0,
    votes: 3,
  },
  {
    id: "747c36fb-838d-4814-9e4e-546ba10ce282",
    Title: "How do we use React Native at Mattermost. Architecture and design",
    talk_id: 451355,
    Overall: 3,
    Fun: 3,
    Originality: 2,
    Creativity: 0,
    Communication: 3,
    Speaker_knowledge: 4,
    Pace_and_timing: 2,
    Recommend_to_others: 3,
    Insight: 0,
    votes: 1,
  },
  {
    id: "13bc6c95-a50a-4ac9-876e-b88c9e599068",
    Title: "Using Data Sketches to extract fast & cheap insights from Big Data",
    talk_id: 462698,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "9d96312e-3b31-4d49-ba53-ebcfa37f2c4f",
    Title: "P3.express the power of routine",
    talk_id: 451321,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "a0b2fbc1-a590-4797-9f10-73d385c6b509",
    Title: "Talking with AI",
    talk_id: 501006,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "a1dcc343-eeba-40e3-95d5-17cd11065167",
    Title:
      "Comprehensive testing strategies for modern microservice architectures",
    talk_id: 457519,
    Overall: 5,
    Fun: 4.75,
    Originality: 4.25,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 9.5,
    Insight: 0,
    votes: 4,
  },
  {
    id: "82956c70-c31f-40b1-b751-f416133e41de",
    Title:
      "The Freedom of Kubernetes requires Chaos Engineering to shine in production",
    talk_id: 464433,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "0d6600a0-6e24-4931-9da6-1e5f92e48ca0",
    Title: "Closing Keynote",
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "5b3a4dd3-4b5f-4e9c-9c93-51a0a11a63c8",
    Title: "Manage memory in the JVM as if it were C",
    talk_id: 439872,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "f190e237-8b8a-454d-9531-109977257372",
    Title:
      "Calibrate Garbage Collection on the Ground and Run Your Java App in the Cloud",
    talk_id: 424687,
    Overall: 4.18,
    Fun: 3.45,
    Originality: 3.82,
    Creativity: 0,
    Communication: 4.36,
    Speaker_knowledge: 4.73,
    Pace_and_timing: 4.55,
    Recommend_to_others: 8.55,
    Insight: 0,
    votes: 11,
  },
  {
    id: "2593906f-c4ed-49fa-9c37-6f7fffe47a1e",
    Title: "Improve team building in full-remote teams",
    talk_id: 436743,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "60eb7273-fe4a-47cf-8b24-6d3e78a7a3d3",
    Title: "Building Streaming Data Pipelines with Apache Kafka",
    talk_id: 425574,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "cbe77c3e-447f-4f13-91b7-8f94a53aa8d3",
    Title: "Three cups of Java",
    talk_id: 422824,
    Overall: 3,
    Fun: 1,
    Originality: 5,
    Creativity: 0,
    Communication: 3,
    Speaker_knowledge: 5,
    Pace_and_timing: 4,
    Recommend_to_others: 4,
    Insight: 0,
    votes: 1,
  },
  {
    id: "7a32090c-4c1e-4eb3-a734-d3c334da59f3",
    Title: "Serverless Java with Spring Boot",
    talk_id: 444045,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "66dd825f-8b7e-428a-8655-6e1e41f6e0ee",
    Title: "Lightning Fast E-Commerce: Remix your Shop with Shopify Hydrogen",
    talk_id: 435197,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "ff7ffa13-234a-49ae-9b93-f8d8605b8832",
    Title:
      "One request at a time: Highly available and performant clusters of single threaded nodes",
    talk_id: 430550,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "23386b91-9ccf-4f9d-8e24-6064901c339d",
    Title: "The battle of the AI coding assistants",
    talk_id: 444145,
    Overall: 4.67,
    Fun: 4.33,
    Originality: 4.83,
    Creativity: 0,
    Communication: 4.83,
    Speaker_knowledge: 4.67,
    Pace_and_timing: 4.83,
    Recommend_to_others: 9,
    Insight: 0,
    votes: 6,
  },
  {
    id: "17947fb5-a071-42c9-95d7-cc363739f5d9",
    Title: "5 tips to make your Java apps more awesome",
    talk_id: 427989,
    Overall: 3.25,
    Fun: 2.75,
    Originality: 3.25,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 4.75,
    Pace_and_timing: 3.5,
    Recommend_to_others: 6,
    Insight: 0,
    votes: 4,
  },
  {
    id: "059c8cbf-7258-4a97-8cc9-049503e15695",
    Title: "The Go context package internals",
    talk_id: 455742,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "36fa7ca8-871b-42b0-b6f7-992cdf35d2b2",
    Title: "Web app in Rust: From zero to deployment",
    talk_id: 452185,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "528ec64c-280b-403e-8858-0e085f056816",
    Title: "Jakarta EE: Success comes with strong open source community",
    talk_id: 456251,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "ca654848-b347-4b65-bace-2edc04a4eafd",
    Title:
      "What I learnt running in the artic: Lessons for leadership in engineering",
    talk_id: 456177,
    Overall: 5,
    Fun: 5,
    Originality: 5,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 10,
    Insight: 0,
    votes: 1,
  },
  {
    id: "871d0fc9-1082-4b2d-b516-067133c78734",
    Title: "How would eBPF enhance modern APM?",
    talk_id: 422922,
    Overall: 5,
    Fun: 3,
    Originality: 3,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 10,
    Insight: 0,
    votes: 1,
  },
  {
    id: "4b5efb55-b22c-4ebd-b1a1-04af091ee694",
    Title: "Policy as Code: A Game-changer for Stack Security",
    talk_id: 429531,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "22f15cb5-f1e5-4652-bea2-67ca101fc93a",
    Title: "Extendiendo los microservicios al frontend: Microfrontends.",
    talk_id: 428580,
    Overall: 4.63,
    Fun: 4.88,
    Originality: 4.38,
    Creativity: 0,
    Communication: 4.5,
    Speaker_knowledge: 4.63,
    Pace_and_timing: 4.63,
    Recommend_to_others: 8.63,
    Insight: 0,
    votes: 8,
  },
  {
    id: "64abf312-54a5-4f85-a31d-e8aac4b64645",
    Title: "How smart are smart contract languages?",
    talk_id: 436171,
    Overall: 4,
    Fun: 3.5,
    Originality: 4,
    Creativity: 0,
    Communication: 4.5,
    Speaker_knowledge: 4.5,
    Pace_and_timing: 4,
    Recommend_to_others: 8,
    Insight: 0,
    votes: 2,
  },
  {
    id: "4cecd5c1-5b0c-4cc5-9f42-3939bea00402",
    Title: "Few-shot classification with contrasted learning",
    talk_id: 462975,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "02fc1485-0c3b-4c0c-9354-2925388edce3",
    Title: "Github Actions a la carta",
    talk_id: 496300,
    Overall: 4,
    Fun: 3.5,
    Originality: 4.5,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 5,
    Pace_and_timing: 4.5,
    Recommend_to_others: 7.5,
    Insight: 0,
    votes: 2,
  },
  {
    id: "6c90ed80-bc91-458b-845b-06a0d37d3426",
    Title: "Developer Productivity Engineering: What's in it for me?",
    talk_id: 480878,
    Overall: 4.25,
    Fun: 4.25,
    Originality: 4.38,
    Creativity: 0,
    Communication: 4.75,
    Speaker_knowledge: 4.75,
    Pace_and_timing: 4.13,
    Recommend_to_others: 8.75,
    Insight: 0,
    votes: 8,
  },
  {
    id: "94528b81-1ca3-4aa5-903b-68e98da2cd2b",
    Title: "Sustainable code",
    talk_id: 460744,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "8a1deb88-0118-4aad-9894-150f247f3ca5",
    Title: "Build Automation: Reusing business logic wisely",
    talk_id: 423491,
    Overall: 4,
    Fun: 3.5,
    Originality: 4,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 5,
    Pace_and_timing: 3,
    Recommend_to_others: 7.5,
    Insight: 0,
    votes: 2,
  },
  {
    id: "8fc470a8-c215-4823-bcbd-4a5176d2adda",
    Title: "Stork: descubre servicios fácilmente y selecciona el mejor",
    talk_id: 441378,
    Overall: 3.67,
    Fun: 4,
    Originality: 4,
    Creativity: 0,
    Communication: 4.33,
    Speaker_knowledge: 4.67,
    Pace_and_timing: 4.67,
    Recommend_to_others: 8.67,
    Insight: 0,
    votes: 3,
  },
  {
    id: "72b20022-1495-4ef5-8d30-50590410a945",
    Title:
      "Maven Central++ What's happening at the core of the Java supply chain",
    talk_id: 463068,
    Overall: 4,
    Fun: 5,
    Originality: 4,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 4,
    Pace_and_timing: 4,
    Recommend_to_others: 8,
    Insight: 0,
    votes: 1,
  },
  {
    id: "87d79b55-faca-4203-b715-7c556a2f8b90",
    Title: "No busques más la solución esta en el feedback",
    talk_id: 463320,
    Overall: 4.6,
    Fun: 4.6,
    Originality: 4.6,
    Creativity: 0,
    Communication: 4.4,
    Speaker_knowledge: 5,
    Pace_and_timing: 4.8,
    Recommend_to_others: 9.2,
    Insight: 0,
    votes: 5,
  },
  {
    id: "05c158e0-b03f-4c8d-b0d5-76ac1f19a107",
    Title: "Gentle Introduction to eBPF",
    talk_id: 436500,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "0faafae9-1e18-4134-94d1-37d20bf85d17",
    Title: "Battling your Biased Brain",
    talk_id: 457030,
    Overall: 4.75,
    Fun: 4.25,
    Originality: 4.42,
    Creativity: 0,
    Communication: 4.67,
    Speaker_knowledge: 4.5,
    Pace_and_timing: 4.67,
    Recommend_to_others: 9.08,
    Insight: 0,
    votes: 12,
  },
  {
    id: "b9bfeee0-90cb-4010-9924-666044252d9f",
    Title: "Feature flags unleashed",
    talk_id: 434873,
    Overall: 4.5,
    Fun: 4.5,
    Originality: 4.5,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 8.5,
    Insight: 0,
    votes: 2,
  },
  {
    id: "0f395107-1c7e-413c-82e2-b67402b97037",
    Title: "GitOps for Reproducible Machine Learning",
    talk_id: 447226,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "a9a98753-eed3-4ee2-a50e-98223875b5f4",
    Title: "Secret Shortcuts of Loading Web Performance",
    talk_id: 431645,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "3ecf931d-4373-4ac4-bbc2-0254e517cff2",
    Title: "The top 5 JavaScript issues in all our codebases",
    talk_id: 457299,
    Overall: 4,
    Fun: 4,
    Originality: 3,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 4,
    Pace_and_timing: 4,
    Recommend_to_others: 6,
    Insight: 0,
    votes: 1,
  },
  {
    id: "d6ef956e-d438-4eed-9899-a76b87f03ffd",
    Title: "Observability For Java Devs - 2023 Edition",
    talk_id: 464668,
    Overall: 3.67,
    Fun: 3,
    Originality: 3.33,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 4.67,
    Pace_and_timing: 3.67,
    Recommend_to_others: 7,
    Insight: 0,
    votes: 3,
  },
  {
    id: "f66abadd-bae9-4491-8de1-29e4c1771e62",
    Title: "De Design Thinking a Circular Design Thinking",
    talk_id: 435070,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "5cea782e-7841-45c6-8dbc-ac781ee91368",
    Title: "To the moon via the cloud!",
    talk_id: 504837,
    Overall: 3.94,
    Fun: 3.33,
    Originality: 3.94,
    Creativity: 0,
    Communication: 3.89,
    Speaker_knowledge: 4.39,
    Pace_and_timing: 3.94,
    Recommend_to_others: 7.22,
    Insight: 0,
    votes: 18,
  },
  {
    id: "ec28d952-7ca7-4037-a7ba-13755251d602",
    Title: "Getting started with Qwik City",
    talk_id: 423145,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "2b4db6f0-d660-4074-af14-fd98d675f891",
    Title: "Profiling your Java Application - A Beginner’s Guide",
    talk_id: 426385,
    Overall: 4.85,
    Fun: 4.92,
    Originality: 4.77,
    Creativity: 0,
    Communication: 4.85,
    Speaker_knowledge: 4.92,
    Pace_and_timing: 4.69,
    Recommend_to_others: 9.77,
    Insight: 0,
    votes: 13,
  },
  {
    id: "8006f6f1-aecc-43c7-ad17-28c3e46fd64e",
    Title: "La legalidad en la Matrix",
    talk_id: 432075,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "38ad1393-20cc-4fbe-8e3b-b871519fcf7d",
    Title:
      "Entity Framework (Core) Unchained: Getting the Best Performance from Your ORM",
    talk_id: 459892,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "cd111f8b-18d6-48c9-a11f-a00405a9d8ca",
    Title: "Practical Pipelines: A Houseplant Soil Alerting System with ksqlDB",
    talk_id: 425572,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "68947b71-0fe1-4a11-a3a5-72261cc63254",
    Title:
      "Revolutionizing Web Development with Astro Build: Leveraging Framework-Agnostic Zero-JavaScript Opt",
    talk_id: 422975,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "bb6dd0ac-31ff-4e0f-8229-75f29d6ec169",
    Title: "Developing portable eBPF applications",
    talk_id: 455234,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "d12bd137-4b5e-4677-b624-50c01d7e1b9b",
    Title: "Core Web Vitals under control",
    talk_id: 431225,
    Overall: 4,
    Fun: 2.67,
    Originality: 3,
    Creativity: 0,
    Communication: 4.33,
    Speaker_knowledge: 4.33,
    Pace_and_timing: 4.67,
    Recommend_to_others: 7,
    Insight: 0,
    votes: 3,
  },
  {
    id: "dd788ad3-3d97-46ba-8f5d-d34ee568feb9",
    Title: "Hands-on: Performance Testing Java Applications",
    talk_id: 465871,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "9537836d-5393-4dae-8ce9-7ff7124ff906",
    Title: "Future of Service Mesh is Sidecar-less with Istio Ambient Mesh",
    talk_id: 444109,
    Overall: 4.67,
    Fun: 4,
    Originality: 4,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 4.67,
    Recommend_to_others: 9.33,
    Insight: 0,
    votes: 3,
  },
  {
    id: "eb10c36c-ff1e-4295-b55d-3d7fd7aefcae",
    Title: "Closing Ceremony",
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "7e7f4379-a76a-4f41-b248-aa70f48d8dc2",
    Title: "Welcome Ceremony",
    Overall: 4.58,
    Fun: 4.16,
    Originality: 4.21,
    Creativity: 0,
    Communication: 4.53,
    Speaker_knowledge: 4.53,
    Pace_and_timing: 4.42,
    Recommend_to_others: 8.68,
    Insight: 0,
    votes: 19,
  },
  {
    id: "e5d738ab-3c32-42ee-b79d-187c20fcb1c4",
    Title:
      "Ya sé Machine Learning pero siento que no estoy preparada para el mundo real…",
    talk_id: 461645,
    Overall: 5,
    Fun: 5,
    Originality: 4.5,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 9.5,
    Insight: 0,
    votes: 2,
  },
  {
    id: "41581f3b-25c6-408e-b97b-39b1d52c3465",
    Title: "Aprendiendo a gestionar... por las bravas",
    talk_id: 424305,
    Overall: 4.25,
    Fun: 4.38,
    Originality: 4.63,
    Creativity: 0,
    Communication: 4.5,
    Speaker_knowledge: 4.5,
    Pace_and_timing: 4,
    Recommend_to_others: 7.88,
    Insight: 0,
    votes: 8,
  },
  {
    id: "eacf9785-8840-4f3c-9c27-1b163d21e212",
    Title: "Say goodbye to bugs and anti-patterns with Error Prone",
    talk_id: 453688,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "dedd0e09-8086-440f-8663-6cfa0f9b11e0",
    Title: "Decentralizing with QR Codes",
    talk_id: 435326,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "d39bb9c1-58c9-4632-87f4-ec2b783e37ec",
    Title: "Una saga de infortunios de renderizado web",
    talk_id: 439306,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "4d275872-7229-41f5-9ad6-f51f935c4d39",
    Title:
      "De la estrategia a la ejecución. ¿Qué significa realmente ser ágil?",
    talk_id: 464045,
    Overall: 4,
    Fun: 3,
    Originality: 4,
    Creativity: 0,
    Communication: 4,
    Speaker_knowledge: 5,
    Pace_and_timing: 5,
    Recommend_to_others: 9,
    Insight: 0,
    votes: 1,
  },
  {
    id: "ac69707f-5fcd-4076-9f61-371ace2cf034",
    Title: "Parenting + Sports = Tech Teams Management",
    talk_id: 506333,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "c54e8243-8d0b-4fb5-9bc3-4da91f313306",
    Title:
      "A Monolith on the Dissecting Table: The Strangler Fig Pattern in Action",
    talk_id: 436717,
    Overall: 4.43,
    Fun: 3.86,
    Originality: 4.14,
    Creativity: 0,
    Communication: 4.86,
    Speaker_knowledge: 4.71,
    Pace_and_timing: 4.43,
    Recommend_to_others: 8.43,
    Insight: 0,
    votes: 7,
  },
  {
    id: "79df9d68-2524-4e27-aae5-80b5deed27b6",
    Title: "Let’s have some effective REST!",
    talk_id: 445630,
    Overall: 3.83,
    Fun: 3.83,
    Originality: 3.83,
    Creativity: 0,
    Communication: 4.5,
    Speaker_knowledge: 4.67,
    Pace_and_timing: 4.5,
    Recommend_to_others: 7.33,
    Insight: 0,
    votes: 6,
  },
  {
    id: "5f5970e3-7c18-4f24-9e76-0e647cf17e18",
    Title: "Clean Hexagonal Onion with a dash of DDD in Spring",
    talk_id: 449184,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "46a93146-f6b1-404e-9ae2-8450385445bd",
    Title: "The Need For Speed: Scaling Go Microservices",
    talk_id: 441962,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "46e621bb-06e0-46d9-b1b1-8b081856921e",
    Title: "Tips to fight impostor syndrome",
    talk_id: 422753,
    Overall: 4.62,
    Fun: 4.31,
    Originality: 4.69,
    Creativity: 0,
    Communication: 4.08,
    Speaker_knowledge: 4.92,
    Pace_and_timing: 4.69,
    Recommend_to_others: 8.46,
    Insight: 0,
    votes: 13,
  },
  {
    id: "b74e5de4-adc7-4ae0-b933-cecde5c6e5fa",
    Title: "Low Code No Code and Full Code Integrations",
    talk_id: 464060,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "dff65394-b011-4d1c-94a4-20401aecfc32",
    Title: "Qualities of a Highly Effective Architect",
    Overall: 4.33,
    Fun: 4.33,
    Originality: 4.33,
    Creativity: 0,
    Communication: 4.67,
    Speaker_knowledge: 5,
    Pace_and_timing: 4.33,
    Recommend_to_others: 9,
    Insight: 0,
    votes: 3,
  },
  {
    id: "c6af1584-34f1-46ff-b06c-0c20492173d2",
    Title: "Human vs AI: How to ship secure code",
    talk_id: 459047,
    Overall: 4.25,
    Fun: 4,
    Originality: 4.5,
    Creativity: 0,
    Communication: 4.5,
    Speaker_knowledge: 4.75,
    Pace_and_timing: 4.5,
    Recommend_to_others: 9,
    Insight: 0,
    votes: 4,
  },
  {
    id: "9d52445b-85bb-4877-b576-0d64291a53a8",
    Title:
      "Whispering into the Future: Reinventing Speech-to-Text Transcriptions with Go and Whisper",
    talk_id: 444497,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "a541fd55-87ce-44ad-a428-308e0b8a5375",
    Title: "Virtual Threads in action!",
    talk_id: 439299,
    Overall: 3.75,
    Fun: 4,
    Originality: 3.75,
    Creativity: 0,
    Communication: 4.25,
    Speaker_knowledge: 4.25,
    Pace_and_timing: 4.25,
    Recommend_to_others: 7.5,
    Insight: 0,
    votes: 4,
  },
  {
    id: "dc1d3c4a-4852-404c-870c-8fdc727bc2ea",
    Title: "OpenTelemetry 101",
    talk_id: 492827,
    Overall: 5,
    Fun: 4,
    Originality: 4,
    Creativity: 0,
    Communication: 5,
    Speaker_knowledge: 5,
    Pace_and_timing: 4,
    Recommend_to_others: 10,
    Insight: 0,
    votes: 1,
  },
  {
    id: "728147e3-2d61-449b-8ad3-8356fae7b1dc",
    Title:
      "Java Kotlin Code Coverage and their best friend - bytecode: scandals intrigues investigations",
    talk_id: 463275,
    Overall: 4.5,
    Fun: 4.38,
    Originality: 4.75,
    Creativity: 0,
    Communication: 4.5,
    Speaker_knowledge: 4.88,
    Pace_and_timing: 4,
    Recommend_to_others: 8.63,
    Insight: 0,
    votes: 8,
  },
  {
    id: "72094265-b495-4af6-aee9-6904b92ac4c3",
    Title: "Building modular libraries for 120 teams: our findings",
    talk_id: 437675,
    Overall: 3.86,
    Fun: 3,
    Originality: 3.57,
    Creativity: 0,
    Communication: 3.86,
    Speaker_knowledge: 4.29,
    Pace_and_timing: 4.29,
    Recommend_to_others: 7.29,
    Insight: 0,
    votes: 7,
  },
  {
    id: "dda41162-5420-46d2-9b67-d44ecc6e830e",
    Title: "Sleep Soundly: Reliable Alerting with Unit Testing in Prometheus",
    talk_id: 464210,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
  {
    id: "c0bc8665-59b4-44d1-af12-bd8b96c4da87",
    Title: "Building Performant Applications at Scale with Qwik-City",
    talk_id: 423107,
    Overall: 4.67,
    Fun: 4.56,
    Originality: 4.33,
    Creativity: 0,
    Communication: 4.78,
    Speaker_knowledge: 4.89,
    Pace_and_timing: 4.44,
    Recommend_to_others: 9.11,
    Insight: 0,
    votes: 9,
  },
  {
    id: "0ae59050-a1be-4813-946d-7ff73cf6372e",
    Title: "Build a Multichannel Hub with Apache Camel and Quarkus Workshop",
    talk_id: 460410,
    Overall: 0,
    Fun: 0,
    Originality: 0,
    Creativity: 0,
    Communication: 0,
    Speaker_knowledge: 0,
    Pace_and_timing: 0,
    Recommend_to_others: 0,
    Insight: 0,
    votes: 0,
  },
];
